import React from 'react';
import classnames from 'classnames';
import { useStateContext } from 'components/VideoCustom/context';
import styles from 'components/VideoCustom/components/Time/Time.scss';

interface TimeProps {
  /** Passed className */
  className?: string;
  /** Type of the time */
  type?: 'current' | 'duration';
}

const Time = ({ className, type = 'current' }: TimeProps) => {
  const { timeCurrent, timeDuration } = useStateContext();
  const isDurationTime = type === 'duration';
  const time = isDurationTime ? timeDuration : timeCurrent;

  const convertTime = () => {
    const minutes = Math.floor(time / 60) || 0;
    const seconds = Math.floor(time - minutes * 60) || 0;
    const formatTime = (timeToFormat: string | number) => {
      if (typeof timeToFormat === 'string') {
        return `0${timeToFormat}`;
      }
      return timeToFormat >= 10 ? timeToFormat : `0${timeToFormat}`;
    };

    return `${formatTime(minutes)}:${formatTime(seconds)}`;
  };

  return (
    <time
      data-testid={`${type}.time`}
      dateTime={isDurationTime ? convertTime() : undefined}
      className={classnames(styles[`time-${type}`], className)}
    >
      {convertTime()}
    </time>
  );
};

export default Time;
