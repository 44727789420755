import React from 'react';
import { reload } from '@xxxlgroup/hydra-icons';
import {
  useDOMReferencesContext,
  useDispatchContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import IconButton from 'components/IconButton';
import { SET_VIDEO_ENDED } from 'components/VideoCustom/actions';

import styles from 'components/VideoCustom/components/Replay/Replay.scss';

const Replay = () => {
  const { dispatch } = useDispatchContext();
  const { videoRef } = useDOMReferencesContext();
  const { label = {} } = useTranslationsContext();
  const { replay: replayTranslation = 'replay video' } = label;

  const handleReplay = () => {
    const video = videoRef.current;

    if (video) {
      video.currentTime = 0;
      video.play();
    }

    dispatch({
      type: SET_VIDEO_ENDED,
      payload: false,
    });
  };

  return (
    <IconButton
      ariaLabel={replayTranslation}
      className={styles.button}
      data-testid="replay.btn"
      glyph={reload}
      onClick={handleReplay}
    />
  );
};

export default Replay;
