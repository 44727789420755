import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { expand } from '@xxxlgroup/hydra-icons';
import IconButton from 'components/IconButton';
import {
  SET_FULLSCREEN_IS_ENABLED_ON_BROWSER,
  SET_FULLSCREEN_STATUS,
} from 'components/VideoCustom/actions';
import {
  useDispatchContext,
  useDOMReferencesContext,
  useStateContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import styles from 'components/VideoCustom/components/FullScreen/FullScreen.scss';

const SSRFullScreen = () => null;
const FullScreen = ({ className }: { className?: string }) => {
  const { dispatch } = useDispatchContext();
  const { fullScreenActive, fullScreenEnabled } = useStateContext();
  const { videoRef, playerRef } = useDOMReferencesContext();
  const { label = {} } = useTranslationsContext();
  const { fullScreen: fullScreenTranslation = 'expand video to fullscreen' } =
    label;

  const requestFullScreenProperty = [
    'requestFullscreen',
    'webkitRequestFullscreen',
    'mozRequestFullScreen',
    'msRequestFullscreen',
  ].find((property) =>
    Object.prototype.hasOwnProperty.call(Element.prototype, property),
  );

  // Define the correct exit-fullscreen property, depends on the browser
  const exitFullScreenProperty = [
    'exitFullscreen',
    'webkitExitFullscreen',
    'mozCancelFullScreen',
    'msExitFullscreen',
  ].find((property) =>
    Object.prototype.hasOwnProperty.call(Document.prototype, property),
  );

  // Define the correct fullscreen-status property, depends on the browser
  const isFullScreenProperty = [
    'fullscreen',
    'webkitIsFullScreen',
    'mozFullScreen',
    'msFullscreenElement',
    'fullscreenElement',
  ].find((property) =>
    Object.prototype.hasOwnProperty.call(Document.prototype, property),
  );

  // Define the correct fullscreen-change event, depends on the browser
  const fullscreenChangeEvent = [
    'fullscreenchange',
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'MSFullscreenChange',
  ].find((property) =>
    Object.prototype.hasOwnProperty.call(Document.prototype, `on${property}`),
  );

  // Check if the browser/device support the fullScreen API
  const browserEnabledFullscreen: boolean = [
    'fullscreenEnabled',
    'webkitFullscreenEnabled',
    'webkitSupportsFullscreen',
    'mozFullScreenEnabled',
    'msFullscreenEnabled',
  ].some((property) =>
    Object.prototype.hasOwnProperty.call(Document.prototype, property),
  );

  // Check if the fullScreen is active
  const isFullScreen = useCallback(
    () =>
      isFullScreenProperty &&
      document[isFullScreenProperty as keyof typeof document],
    [isFullScreenProperty],
  );

  useEffect(() => {
    dispatch({
      type: SET_FULLSCREEN_IS_ENABLED_ON_BROWSER,
      payload: browserEnabledFullscreen,
    });
  }, [browserEnabledFullscreen, dispatch]);

  // Set fullscreen related data
  useEffect(() => {
    const handleFullScreenState = () => {
      if (isFullScreen()) {
        dispatch({
          type: SET_FULLSCREEN_STATUS,
          payload: true,
        });
      } else {
        dispatch({
          type: SET_FULLSCREEN_STATUS,
          payload: false,
        });
      }
    };

    if (fullScreenEnabled && fullscreenChangeEvent) {
      document.addEventListener(fullscreenChangeEvent, handleFullScreenState);
    }

    return () => {
      if (fullScreenEnabled && fullscreenChangeEvent) {
        document.removeEventListener(
          fullscreenChangeEvent,
          handleFullScreenState,
        );
      }
    };
  }, [
    dispatch,
    fullScreenActive,
    fullScreenEnabled,
    fullscreenChangeEvent,
    isFullScreen,
  ]);

  // Requesting fullScreen for browsers, which support the fullScreen API
  const requestFullScreen = () => {
    if (playerRef.current && requestFullScreenProperty) {
      return (
        playerRef.current[
          requestFullScreenProperty as keyof typeof playerRef.current
        ] as () => void
      )();
    }
    return null;
  };

  // Exiting fullScreen
  const exitFullScreen = () => {
    if (exitFullScreenProperty) {
      return (
        document[exitFullScreenProperty as keyof typeof document] as () => void
      )();
    }

    return null;
  };

  // Requesting fullScreen for browsers, which not support the fullScreen API (iphone)
  function requestFullscreenForNotSupportedBrowsers() {
    if (videoRef.current?.webkitEnterFullscreen) {
      videoRef.current.webkitEnterFullscreen();
    }
  }

  const toggleFullScreen = () => {
    if (!fullScreenEnabled) {
      requestFullscreenForNotSupportedBrowsers();
    } else if (!isFullScreen()) {
      requestFullScreen();
    } else {
      exitFullScreen();
    }
  };

  return (
    <IconButton
      ariaLabel={fullScreenTranslation}
      className={classnames(styles.button, className)}
      data-testid="fullScreen.btn"
      glyph={expand}
      onClick={toggleFullScreen}
    />
  );
};

const IsomorphicFullscreen =
  typeof window === 'undefined' ? SSRFullScreen : FullScreen;

export default IsomorphicFullscreen;
