import React from 'react';
import classnames from 'classnames';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import {
  useDispatchContext,
  useDOMReferencesContext,
  useStateContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import { SET_CURRENT_TIME } from 'components/VideoCustom/actions';
import styles from 'components/VideoCustom/components/SeekBar/SeekBar.scss';

const SeekBar = ({ className }: { className?: string }) => {
  const { buffered, timeCurrent, timeDuration } = useStateContext();
  const { dispatch } = useDispatchContext();
  const { videoRef } = useDOMReferencesContext();
  const { label = {} } = useTranslationsContext();
  const { seekBar: seekBarTranslation = 'video seekbar' } = label;
  const bufferedLength = buffered?.length ?? 0;
  const calculatePercent = (partial: number, total: number) =>
    (partial / total) * 100 || 0;
  const playingProgress = calculatePercent(timeCurrent, timeDuration);

  let bufferEnd = bufferedLength > 0 ? buffered?.end(bufferedLength - 1) : 0;

  if (bufferEnd && bufferEnd > timeDuration) {
    bufferEnd = timeDuration;
  }

  const bufferedInterval = (left: string, width: string, key: number) => (
    <span
      data-testid="seek.bar.buffer"
      className={styles.bufferInterval}
      style={{ left, width }}
      key={key}
    />
  );

  const generateBufferIntervals = () => {
    const intervals = [];

    if (bufferedLength > 0 && buffered && bufferEnd) {
      for (let i = 0; i < bufferedLength; i += 1) {
        const start = buffered.start(i);
        const end = buffered.end(i);
        const left = `${calculatePercent(start, bufferEnd)}%`;
        const width = `${calculatePercent(end - start, bufferEnd)}%`;
        const interval = bufferedInterval(left, width, i);

        intervals.push(interval);
      }
    }

    return isArrayEmpty(intervals) ? null : intervals;
  };

  const handleSeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      const newCurrentTime = (event.target.valueAsNumber * timeDuration) / 100;
      dispatch({ type: SET_CURRENT_TIME, payload: newCurrentTime });
      /* eslint-disable no-param-reassign */
      videoRef.current.currentTime = newCurrentTime;
    }
  };

  const bufferIndicator = (
    <div
      style={{ width: `${calculatePercent(bufferEnd ?? 0, timeDuration)}%` }}
      className={styles.bufferWrapper}
    >
      {generateBufferIntervals()}
    </div>
  );

  // Stopping propagation onMouseMove and onTouchMove is making sure,
  // that the Seekbar will works properly inside of the Slider-component
  const stopPropagation = (
    e:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent
      | React.TouchEvent<HTMLDivElement>,
  ) => e.stopPropagation();

  const slider = (
    <>
      <div
        data-testid="seek.bar.progress"
        className={styles.progress}
        style={{ width: `${playingProgress}%` }}
      >
        <span className={styles.handler} />
      </div>
      <input
        aria-label={seekBarTranslation}
        className={styles.slider}
        data-testid="seek.bar.slider"
        min="0"
        max="100"
        onChange={handleSeekChange}
        step="1"
        type="range"
        value={playingProgress}
      />
    </>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classnames(styles.track, className)}
      data-testid="seek.bar"
      onMouseMove={stopPropagation}
      onTouchMove={stopPropagation}
      onKeyUp={stopPropagation}
    >
      {bufferIndicator}
      {slider}
    </div>
  );
};

export default SeekBar;
