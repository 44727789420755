import React from 'react';
import VideoCustom from 'components/VideoCustom';
import VideoExternal from 'components/VideoExternal';
import { VideoExternalProps } from 'components/VideoExternal/VideoExternal';
import { VideoCustomProps } from 'components/VideoCustom/VideoCustom.types';

const VideoFactory: React.FC<VideoExternalProps & VideoCustomProps> = (
  props,
) => {
  if (props.provider) {
    return React.createElement(VideoExternal, props);
  }
  return React.createElement(VideoCustom, props);
};

export default VideoFactory;
