import React from 'react';
import { play as playIcon, pause as pauseIcon } from '@xxxlgroup/hydra-icons';
import {
  useDOMReferencesContext,
  useStateContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import IconButton from 'components/IconButton';
import styles from 'components/VideoCustom/components/PlayPause/PlayPause.scss';

const PlayPause = () => {
  const { timeEnded, videoPlaying } = useStateContext();
  const { videoRef } = useDOMReferencesContext();
  const { label = {} } = useTranslationsContext();
  const { playPause: playPauseTranslation = 'play/pause video' } = label;

  const shouldPause = videoPlaying && !timeEnded;
  const glyph = shouldPause ? pauseIcon : playIcon;
  const togglePlay = () => videoRef.current?.[shouldPause ? 'pause' : 'play']();

  return (
    <IconButton
      ariaLabel={playPauseTranslation}
      className={styles.button}
      data-testid={shouldPause ? 'pause.btn' : 'play.btn'}
      glyph={glyph}
      onClick={togglePlay}
    />
  );
};

export default PlayPause;
