import React, { useRef, useState } from 'react';
import { fastBackward, fastForward } from '@xxxlgroup/hydra-icons';
import Icon from 'components/Icon';
import {
  useDOMReferencesContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import styles from 'components/VideoCustom/components/Skip/Skip.scss';

interface SkipProps {
  type?: 'forward' | 'backward';
}

export const HIDE_BUTTON_INFO_TIMEOUT = 1500;

const Skip = ({ type = 'forward' }: SkipProps) => {
  const SKIP_VALUE = 10;

  const [countBackwardZeroHit, setCountBackwardZeroHit] = useState(0);
  const [countButtonClick, setCountButtonClick] = useState(0);
  const [showInfo, setShowInfo] = useState(false);

  // Saved in useRef to keep the value during component re-renderings
  const doubleTapTimeout = useRef<number>();

  const { videoRef } = useDOMReferencesContext();
  const { label = {}, seconds: secondsTranslation = 'seconds' } =
    useTranslationsContext();
  const { skip: skipTranslation = 'skip video forward/backward' } = label;

  const video = videoRef.current;
  const isForward = type === 'forward';
  const sign = isForward ? '+' : '-';

  const secondsToShow =
    countBackwardZeroHit > 1 ? SKIP_VALUE : SKIP_VALUE * countButtonClick;

  const resetInfo = () => {
    setCountButtonClick(0);
    setShowInfo(false);
    setCountBackwardZeroHit(0);
  };

  const handleDoubleClick = () => {
    doubleTapTimeout.current && clearTimeout(doubleTapTimeout.current);
    setCountButtonClick((prevState) => prevState + 1);
    setShowInfo(true);

    if (video) {
      if (isForward) {
        video.currentTime += SKIP_VALUE;
      } else {
        video.currentTime -= SKIP_VALUE;
        if (video.currentTime <= 0) {
          setCountBackwardZeroHit((prevState) => prevState + 1);
        }
      }
    }

    doubleTapTimeout.current = setTimeout(() => {
      resetInfo();
    }, HIDE_BUTTON_INFO_TIMEOUT);
  };

  return (
    <button
      aria-label={skipTranslation}
      className={styles[`button-${type}`]}
      data-testid={`skip.btn.${type}`}
      onDoubleClick={handleDoubleClick}
    >
      {showInfo && (
        <>
          <div className={styles.circleWrapper}>
            <div
              className={styles[`circle-${type}`]}
              data-testid={`skip.circle.${type}`}
            />
          </div>
          <div
            className={styles[`content-${type}`]}
            data-testid={`skip.content.${type}`}
          >
            <Icon
              glyph={isForward ? fastForward : fastBackward}
              className={styles.icon}
            />
            <span className={styles.text} data-testid="skip.text">
              {`${sign} ${secondsToShow} ${secondsTranslation}`}
            </span>
          </div>
        </>
      )}
    </button>
  );
};

export default Skip;
