import React from 'react';
import classnames from 'classnames';
import { useStateContext } from 'components/VideoCustom/context';
import Loading from 'components/VideoCustom/components/Loading';
import PlayPause from 'components/VideoCustom/components/PlayPause';
import Replay from 'components/VideoCustom/components/Replay';
import Skip from 'components/VideoCustom/components/Skip';
import styles from 'components/VideoCustom/components/ControlsUpper/ControlsUpper.scss';

interface ControlsUpperProps {
  className?: string;
}

const ControlsUpper = ({ className = '' }: ControlsUpperProps) => {
  const {
    isLoading,
    isTouchDevice,
    timeCurrent,
    timeDuration,
    timeEnded,
    videoFirstPlayDone,
  } = useStateContext();

  const showReplay = timeEnded && timeCurrent === timeDuration;
  const showBackdrop = isLoading || showReplay || !videoFirstPlayDone;
  const showSkip = isTouchDevice && videoFirstPlayDone && !timeEnded;

  const renderButton = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (showReplay) {
      return <Replay />;
    }
    return <PlayPause />;
  };

  return (
    <div
      className={classnames(
        className,
        styles.wrapper,
        showBackdrop && styles.backdrop,
      )}
      data-testid="controls.upper"
    >
      {showSkip && (
        <>
          <Skip type="backward" />
          <Skip />
        </>
      )}
      <div className={styles.buttonWrapper}>{renderButton()}</div>
    </div>
  );
};

export default ControlsUpper;
