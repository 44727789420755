import React from 'react';
import LoadingIcon from 'components/Loading';
import styles from 'components/VideoCustom/components/Loading/Loading.scss';
import { VariantType } from 'components/Loading/Loading';

const Loading = () => (
  <LoadingIcon
    className={styles.icon}
    data-testid="loading.icon"
    type="dots"
    variant={VariantType.LIGHT}
  />
);

export default Loading;
