export const SET_BUFFERED = 'SET_BUFFERED';
export const SET_ERROR = 'SET_ERROR';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
export const SET_DURATION = 'SET_DURATION';
export const SET_FULLSCREEN_STATUS = 'SET_FULLSCREEN_STATUS';
export const SET_FULLSCREEN_IS_ENABLED_ON_BROWSER =
  'SET_IF_FULLSCREEN_IS_ENABLED_ON_BROWSER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_TOUCH_DEVICE = 'SET_IS_TOUCH_DEVICE';
export const SET_MUTED = 'SET_MUTED';
export const SET_USER_IS_ACTIVE_ON_FULLSCREEN =
  'SET_IF_USER_IS_ACTIVE_ON_FULLSCREEN';
export const SET_VIDEO_ENDED = 'SET_VIDEO_ENDED';
export const SET_VIDEO_FIRST_PLAY_DONE = 'SET_VIDEO_FIRST_PLAY_DONE';
export const SET_VIDEO_PLAYING = 'SET_VIDEO_PLAYING';
