import React from 'react';
import classnames from 'classnames';
import generateQueryStringFromObject from '@xxxlgroup/hydra-utils/urls/generateQueryStringFromObject';
import styles from 'components/VideoExternal/VideoExternal.scss';

export interface VideoExternalProps {
  autoplay?: boolean;
  className?: {
    wrapper?: string;
    player?: string;
  };
  disableFullScreen?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsinline?: boolean;
  provider: 'vimeo' | 'youtube';
  rel?: boolean;
  showYoutubeLogo?: boolean;
  videoId: string;
  title?: string;
}

interface VideoUrlData {
  baseUrl: string;
  params: Record<string, unknown>;
}

const VideoExternal = ({
  autoplay = false,
  className = {},
  disableFullScreen = false,
  loop = false,
  muted = true,
  playsinline = true,
  provider,
  rel = false,
  showYoutubeLogo = false,
  videoId,
  title = '',
}: VideoExternalProps) => {
  if (!videoId || !provider) {
    return null;
  }

  const getYoutubeParams = () => {
    const params: Record<string, unknown> = {
      autoplay,
      fs: !disableFullScreen,
      loop: loop ?? false,
      modestbranding: !showYoutubeLogo,
      mute: muted,
      playsinline: playsinline ? 1 : 0,
      rel: rel ? 1 : 0,
    };

    if (loop) {
      params.playlist = videoId;
    }

    return params;
  };

  const getUrlData = (): VideoUrlData => {
    switch (provider) {
      case 'vimeo':
        return {
          baseUrl: 'https://player.vimeo.com/video/',
          params: {
            autoplay,
            loop,
            muted,
            playsinline,
          },
        };

      case 'youtube':
        return {
          baseUrl: 'https://www.youtube.com/embed/',
          params: getYoutubeParams(),
        };

      default:
        return {
          baseUrl: '',
          params: {},
        };
    }
  };

  const buildUrl = () => {
    const { baseUrl, params } = getUrlData();
    const queryString = generateQueryStringFromObject(params);
    return `${baseUrl}${videoId}?${queryString}`;
  };

  const attributes = {
    allow: `autoplay;${disableFullScreen ? '' : ' fullscreen'}`,
    className: classnames(styles.player, className.player),
    'data-testid': 'video.external.iframe',
    src: buildUrl(),
  };

  return (
    <div
      className={classnames(styles.wrapper, className.wrapper)}
      data-testid="video.external.wrapper"
    >
      <iframe title={title} {...attributes} />
    </div>
  );
};

export default VideoExternal;
