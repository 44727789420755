import React from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { circleExclamationMarkThin as errorIcon } from '@xxxlgroup/hydra-icons';
import { messages as defaultErrorMessages } from 'components/VideoCustom/components/ErrorMessage/messages';
import { useTranslationsContext } from 'components/VideoCustom/context';

import styles from 'components/VideoCustom/components/ErrorMessage/ErrorMessage.scss';

interface ErrorMessageProps {
  code?: number | string;
}

const {
  MEDIA_ERR_ABORTED,
  MEDIA_ERR_DECODE,
  MEDIA_ERR_NETWORK,
  MEDIA_ERR_SRC_NOT_SUPPORTED,
  notSupportedVideoTag: notSupportedMessage,
  suggestReload: reloadMessage,
  unknownError,
} = defaultErrorMessages;

const ErrorMessage = ({ code = '' }: ErrorMessageProps) => {
  const { error = {} } = useTranslationsContext();

  const {
    aborted = MEDIA_ERR_ABORTED,
    decode = MEDIA_ERR_DECODE,
    defaultError = unknownError,
    network = MEDIA_ERR_NETWORK,
    notSupportedSrc = MEDIA_ERR_SRC_NOT_SUPPORTED,
    notSupportedVideoTag = notSupportedMessage,
    suggestReload = reloadMessage,
  } = error;

  const messages: Record<string, string> = {
    1: aborted,
    2: network,
    3: decode,
    4: notSupportedSrc,
    notSupportedVideoTag,
  };

  const getMessage = () => {
    if (Object.prototype.hasOwnProperty.call(messages, code)) {
      return messages[code];
    }
    return defaultError;
  };

  const [iconStyle, iconClassName] = pseudoIcon(errorIcon);

  return (
    <p className={classnames(styles.error, iconClassName)} style={iconStyle}>
      {code !== 'notSupportedVideoTag' && (
        <span className={styles.reloadMessage} data-testid="reload.suggestion">
          {suggestReload}
        </span>
      )}
      <span className={styles.errorMessage} data-testid="error.message">
        {getMessage()}
      </span>
    </p>
  );
};

export default ErrorMessage;
