import React from 'react';
import classnames from 'classnames';
import styles from 'components/VideoCustom/components/Gradient/Gradient.scss';

const Gradient = (props: { className?: string }) => (
  <div
    className={classnames(props.className, styles.gradient)}
    data-testid="gradient"
  />
);

export default Gradient;
