import React from 'react';
import classnames from 'classnames';
import Mute from 'components/VideoCustom/components/Mute';
import FullScreen from 'components/VideoCustom/components/FullScreen';
import SeekBar from 'components/VideoCustom/components/SeekBar';
import Time from 'components/VideoCustom/components/Time';
import styles from 'components/VideoCustom/components/ControlsLower/ControlsLower.scss';

interface ControlsLowerProps {
  className?: string;
  /** Variant of the controls */
  variant?: 'short' | 'long';
}

const ControlsLower = ({
  className = '',
  variant = 'short',
}: ControlsLowerProps) => {
  const controls =
    variant === 'long' ? (
      <>
        <Mute className={styles.mute} />
        <Time className={styles.currentTime} />
        <Time className={styles.durationTime} type="duration" />
        <SeekBar className={styles.seekBar} />
        <FullScreen className={styles.fullScreen} />
      </>
    ) : (
      <>
        <Mute className={styles.mute} />
        <FullScreen className={styles.fullScreen} />
      </>
    );

  return (
    <div
      data-testid={`controls.lower.${variant}`}
      className={classnames(className, styles[`${variant}Controls`])}
    >
      {controls}
    </div>
  );
};

export default ControlsLower;
