/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
  useEffect,
} from 'react';

import classnames from 'classnames';
import { rail } from '@xxxlgroup/hydra-config';
import { noop } from '@xxxlgroup/hydra-utils/common';
import createIntersectionObserver from '@xxxlgroup/hydra-utils/observers';
import ErrorMessage from 'components/VideoCustom/components/ErrorMessage';
import ControlsLower from 'components/VideoCustom/components/ControlsLower';
import ControlsUpper from 'components/VideoCustom/components/ControlsUpper';
import Gradient from 'components/VideoCustom/components/Gradient';
import {
  SET_USER_IS_ACTIVE_ON_FULLSCREEN,
  SET_VIDEO_ENDED,
  SET_VIDEO_FIRST_PLAY_DONE,
  SET_VIDEO_PLAYING,
} from 'components/VideoCustom/actions';
import {
  InitialState,
  initialState,
  reducer,
  ReducerAction,
} from 'components/VideoCustom/reducer';
import {
  StateContext,
  DispatchContext,
  DOMReferencesContext,
  TranslationsContext,
  HTMLVideoCustomElement,
} from 'components/VideoCustom/context';
import {
  generatePosterUrl,
  generateVideoUrl,
  PosterSource,
} from 'components/VideoCustom/utils';
import {
  VideoCustomHandle,
  VideoCustomProps,
} from 'components/VideoCustom/VideoCustom.types';
import styles from 'components/VideoCustom/VideoCustom.scss';
import { useVideoEventListeners } from 'components/VideoCustom/customHooks/useVideoEventListeners';
import { Video } from 'types/typeDefinitions';

function handleMouseMove(
  fullScreenActive: boolean,
  isTouchDevice: boolean,
  startUserInteractionTimeout: () => void,
) {
  return fullScreenActive && !isTouchDevice
    ? startUserInteractionTimeout
    : undefined;
}

const generateVideoPlayerStyle = (
  fullScreenEnabled: boolean,
  fullScreenActiveUser: boolean,
  fullScreenActive: boolean,
  className?: {
    player?: string;
    video?: string;
  },
) =>
  classnames(
    styles.player,
    {
      [styles.fullscreen]: fullScreenActive && fullScreenEnabled,
      [styles.userNotActive]: !fullScreenActiveUser && fullScreenActive,
    },
    className?.player,
  );

const getControlsClassNames = (
  videoFirstPlayDone: boolean,
  videoPlaying: boolean,
  controlsVisible: boolean,
  fullScreenActive: boolean,
  fullScreenActiveUser: boolean,
) => [
  {
    [styles.delay]: videoFirstPlayDone && !videoPlaying && !controlsVisible,
    [styles.vanishFullScreen]: !fullScreenActiveUser && fullScreenActive,
    [styles.visible]: !videoFirstPlayDone || controlsVisible,
  },
  {
    [styles.delay]: !videoPlaying && !controlsVisible,
    [styles.enabled]: videoFirstPlayDone,
    [styles.vanishFullScreen]: !fullScreenActiveUser && fullScreenActive,
    [styles.visible]: controlsVisible,
  },
];

const USER_INTERACTION_TIMEOUT_ON_FULLSCREEN = 2000;
const VideoCustom = forwardRef<VideoCustomHandle, VideoCustomProps>(
  (
    {
      autoplay = false,
      className,
      hideControls = false,
      i18n = {},
      loop = false,
      muted = true,
      onPause = noop,
      onPlay = noop,
      onEnded = noop,
      onResume = noop,
      onPlayProgessChanged = noop,
      poster,
      posterUrlGenerator = generatePosterUrl,
      quality = '720p',
      source,
      type = 'mp4',
      variant = 'short',
      videoUrlGenerator = generateVideoUrl,
      title = '',
      ...props
    },
    ref,
  ) => {
    const [state, dispatch] = useReducer<
      React.Reducer<InitialState, ReducerAction>
    >(reducer, initialState);
    const [controlsVisible, setControlsVisible] = useState(false);

    const {
      error,
      errorCode,
      fullScreenActive,
      fullScreenEnabled,
      fullScreenActiveUser,
      isTouchDevice,
      timeEnded,
      videoFirstPlayDone,
      videoPlaying,
    } = state;

    const playerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoCustomElement>(null);
    const focusTimeoutRef = useRef<number>();

    useImperativeHandle(
      ref,
      () => {
        const video = videoRef.current;
        return {
          play: () => video?.play(),
          pause: () => video?.pause(),
        };
      },
      [videoRef],
    );

    const userInteractionTimeout = useRef<number>();

    const createPosterUrl = useCallback(
      (posterSource?: PosterSource) =>
        posterUrlGenerator({
          posterSource,
          railBaseUrl: rail.baseVideoUrl,
        }),
      [posterUrlGenerator],
    );

    const createVideoUrl = useCallback(
      (videoSource: string | Video) =>
        videoUrlGenerator({
          videoSource,
          type,
          quality,
          railBaseUrl: rail.baseVideoUrl,
        }),
      [quality, type, videoUrlGenerator],
    );

    useEffect(() => {
      /** work around for React bug that applies the mute while being rendered causing a console flush error.
       * Now it applies the muted attribute safely on the second render.
       * Logic is superfluous when the following PR had been merged: https://github.com/facebook/react/pull/20087/files */
      const video = videoRef.current;
      if (video) {
        video.muted = muted;
      }
    }, [autoplay, muted]);

    useEffect(() => {
      const intersectionObserver = createIntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (videoRef.current && autoplay && entry.isIntersecting) {
              videoRef.current.play();
              intersectionObserver.disconnect();
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: [0.1],
        },
      );

      if (videoRef.current && autoplay) {
        intersectionObserver.observe(videoRef.current);
      }

      return () => {
        intersectionObserver.disconnect();
      };
    }, [autoplay]);

    const startUserInteractionTimeout = () => {
      dispatch({
        type: SET_USER_IS_ACTIVE_ON_FULLSCREEN,
        payload: true,
      });

      userInteractionTimeout.current &&
        clearTimeout(userInteractionTimeout.current);
      userInteractionTimeout.current = setTimeout(() => {
        dispatch({
          type: SET_USER_IS_ACTIVE_ON_FULLSCREEN,
          payload: false,
        });
      }, USER_INTERACTION_TIMEOUT_ON_FULLSCREEN);
    };

    useVideoEventListeners(
      onEnded,
      dispatch,
      playerRef,
      videoRef,
      onPlayProgessChanged,
      muted,
    );

    const popControls = () => {
      setControlsVisible(true);
      focusTimeoutRef.current && clearTimeout(focusTimeoutRef.current);
      focusTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
      }, USER_INTERACTION_TIMEOUT_ON_FULLSCREEN);
    };

    const handlePause = useCallback(
      (event: React.SyntheticEvent<HTMLVideoCustomElement>) => {
        dispatch({ type: SET_VIDEO_PLAYING, payload: false });
        onPause(event);
        popControls();
      },
      [dispatch, onPause],
    );

    const handlePlay = useCallback(
      (event: React.SyntheticEvent<HTMLVideoCustomElement>) => {
        dispatch({ type: SET_VIDEO_PLAYING, payload: true });
        if (!videoFirstPlayDone) {
          dispatch({ type: SET_VIDEO_FIRST_PLAY_DONE, payload: false });
          onPlay(event);
        } else {
          onResume(event);
          popControls();
        }
        if (timeEnded) {
          dispatch({ type: SET_VIDEO_ENDED, payload: false });
        }
      },
      [dispatch, onPlay, onResume, timeEnded, videoFirstPlayDone],
    );

    const domReferences = useMemo(
      () => ({
        playerRef,
        videoRef,
      }),
      [playerRef, videoRef],
    );

    const contextValue = useMemo(() => ({ dispatch }), []);

    const [controlsUpperShowHideClassNames, controlsLowerShowHideClassNames] =
      getControlsClassNames(
        videoFirstPlayDone,
        videoPlaying,
        controlsVisible,
        fullScreenActive,
        fullScreenActiveUser,
      );

    return (
      <DispatchContext.Provider value={contextValue}>
        <StateContext.Provider value={state}>
          <DOMReferencesContext.Provider value={domReferences}>
            <TranslationsContext.Provider value={i18n}>
              <div
                className={generateVideoPlayerStyle(
                  fullScreenEnabled,
                  fullScreenActiveUser,
                  fullScreenActive,
                  className,
                )}
                data-testid="player"
                onFocus={popControls}
                onMouseMove={handleMouseMove(
                  fullScreenActive,
                  isTouchDevice,
                  startUserInteractionTimeout,
                )}
                onTouchEnd={handleMouseMove(
                  fullScreenActive,
                  isTouchDevice,
                  startUserInteractionTimeout,
                )}
                ref={playerRef}
              >
                <video
                  className={classnames(styles.video, className?.video)}
                  data-testid="video"
                  disablePictureInPicture
                  loop={loop}
                  onPause={handlePause}
                  onPlay={handlePlay}
                  playsInline
                  poster={!error ? createPosterUrl(poster) : undefined}
                  ref={videoRef}
                  src={createVideoUrl(source)}
                  title={title}
                  {...props}
                >
                  <ErrorMessage code="notSupportedVideoTag" />
                </video>
                {error ? (
                  <ErrorMessage code={errorCode} />
                ) : (
                  !hideControls && (
                    <div className={styles.controls}>
                      <ControlsUpper
                        className={classnames(
                          styles.controlsUpper,
                          controlsUpperShowHideClassNames,
                        )}
                      />
                      <ControlsLower
                        className={classnames(
                          styles.controlsLower,
                          controlsLowerShowHideClassNames,
                        )}
                        variant={variant}
                      />
                      <Gradient
                        className={classnames(
                          styles.controlsLower,
                          controlsLowerShowHideClassNames,
                        )}
                      />
                    </div>
                  )
                )}
              </div>
            </TranslationsContext.Provider>
          </DOMReferencesContext.Provider>
        </StateContext.Provider>
      </DispatchContext.Provider>
    );
  },
);

export default VideoCustom;
// # sourceMappingURL=VideoCustom.js.map
