import { useRef, useCallback } from 'react';
import type {
  UseDurationTimer,
  DurationTimerRef,
  DurationTimerOptions,
} from 'hooks/useDurationTimer/useDurationTimer.types';

const useDurationTimer = (
  initialDuration: number,
  options: DurationTimerOptions = {},
): UseDurationTimer => {
  const { current: durationTimerRef } = useRef<DurationTimerRef>({
    initialDuration,
    options,
  });
  const clearDurationTimeout = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => clearTimeout(durationTimerRef.durationTimeoutId!),
    [durationTimerRef],
  );
  const setDurationTimeout = useCallback(
    (duration: number) => {
      clearDurationTimeout();

      if (duration > 0) {
        durationTimerRef.duration = duration;
        durationTimerRef.durationStartDate = Date.now();
        durationTimerRef.durationTimeoutId = setTimeout(
          () => durationTimerRef.options.onExpired?.(),
          duration,
        );
      }

      return clearDurationTimeout;
    },
    [clearDurationTimeout, durationTimerRef],
  );
  const startDuration = useCallback(
    () => setDurationTimeout(durationTimerRef.initialDuration),
    [durationTimerRef, setDurationTimeout],
  );
  const pauseDuration = useCallback(() => {
    const {
      duration = 0,
      durationStartDate: startDate,
      initialDuration: initDuration,
      options: ops,
    } = durationTimerRef;
    if (startDate && duration) {
      const pauseDate = Date.now();
      const expireDate = startDate + duration;
      const percent = (expireDate - pauseDate) / initDuration;

      durationTimerRef.durationPauseDate = pauseDate;
      clearDurationTimeout();
      ops.onDurationPaused?.(percent, initDuration);

      return percent;
    }
    return 0;
  }, [clearDurationTimeout, durationTimerRef]);
  const resumeDuration = useCallback(
    (durationOffset = 0) => {
      const {
        duration = 0,
        durationPauseDate: pauseDate,
        durationStartDate: startDate,
        initialDuration: initDuration,
        options: ops,
      } = durationTimerRef;
      if (pauseDate && startDate && duration) {
        const expireDate = startDate + duration;
        const remainingDuration = Math.max(expireDate - pauseDate, 0);
        const newDuration = Math.min(
          initDuration,
          Math.max(0, remainingDuration + durationOffset),
        );
        const percent = newDuration / initDuration;

        durationTimerRef.durationPauseDate = undefined;
        setDurationTimeout(newDuration);
        ops.onDurationResumed?.(percent, initDuration, durationOffset);

        return percent;
      }
      return 0;
    },
    [setDurationTimeout, durationTimerRef],
  );

  durationTimerRef.options = options;

  return {
    startDuration,
    pauseDuration,
    resumeDuration,
    clearDuration: clearDurationTimeout,
  };
};

export default useDurationTimer;
