import { Image, Video } from 'types/typeDefinitions';

export interface VideoUrl {
  videoSource: string | Video;
  type: string;
  quality: string;
  railBaseUrl: RailBaseUrl;
}

type RailBaseUrl = string;

export type PosterSource = string | Record<string, string> | Image;
export interface PosterUrl {
  posterSource?: PosterSource;
  railBaseUrl: RailBaseUrl;
}
export function generateVideoUrl({
  videoSource,
  type,
  quality,
  railBaseUrl,
}: VideoUrl) {
  const source =
    typeof videoSource === 'string' ? videoSource : videoSource.hashCode;

  return `${railBaseUrl}${source}/${type}_${quality}`;
}

export function generatePosterUrl({ posterSource, railBaseUrl }: PosterUrl) {
  const source =
    typeof posterSource === 'string' ? posterSource : posterSource?.hashCode;

  return `${railBaseUrl}${source}/thumbs/frame_0001.jpg?upscale=padd&fmt.jpeg.interlaced=true`;
}
