import React, { createContext, useContext } from 'react';
import { initialState, InitialState } from 'components/VideoCustom/reducer';
import { VideoCustomI18n } from 'components/VideoCustom/VideoCustom.types';
import { noop } from '@xxxlgroup/hydra-utils/common';

/* This extension is needed because TS is complaining that
   webkitEnterFullscreen is not existing for the HTMLVideoElement.
 */
export interface HTMLVideoCustomElement extends HTMLVideoElement {
  /** function to enter fullscreen mode */
  webkitEnterFullscreen: () => void | null;
  /** function to close/exit fullscreen mode */
  webkitExitFullscreen: () => void | null;
}

// default values are passed, because they are consumed from the unit tests
export const StateContext = createContext<InitialState>(initialState);
// keep the dispatch() from useReducer separate from the state context, because they don't change together
export const DispatchContext = React.createContext<{
  dispatch: React.Dispatch<DispatchContextType>;
}>({ dispatch: noop });
export const DOMReferencesContext = createContext<{
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  playerRef: React.RefObject<HTMLDivElement>;
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  videoRef: React.RefObject<HTMLVideoCustomElement>;
}>({
  playerRef: { current: null },
  videoRef: { current: null },
});

interface DispatchContextType {
  type: string;
  payload: boolean | number;
}

export const TranslationsContext = createContext<VideoCustomI18n>({});

// the custom hook is created, mainly to be useful for testing,
// since we are not passing props to some components, but directly access the data from the context provider with useContext()
// the custom hook useAppContext, give us the opportunity to mock it in the tests
export const useStateContext = () => useContext(StateContext);
export const useDispatchContext = () => useContext(DispatchContext);
export const useDOMReferencesContext = () => useContext(DOMReferencesContext);
export const useTranslationsContext = () => useContext(TranslationsContext);
export default StateContext;
