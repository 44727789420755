export const messages = {
  MEDIA_ERR_ABORTED: 'You aborted the video playback.',
  MEDIA_ERR_DECODE:
    'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.',
  MEDIA_ERR_NETWORK:
    'A network error caused the video download to fail part-way.',
  MEDIA_ERR_SRC_NOT_SUPPORTED:
    'The video could not be loaded, either because the server or network failed or because the format is not supported.',
  notSupportedVideoTag: `Sorry, your browser doesn't support embedded videos.`,
  suggestReload: 'Try to reload the page.',
  unknownError: 'An unknown error occurred.',
};
