import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { volumeHover, volumeOff, volumeOn } from '@xxxlgroup/hydra-icons';
import IconButton from 'components/IconButton';
import {
  useDispatchContext,
  useDOMReferencesContext,
  useStateContext,
  useTranslationsContext,
} from 'components/VideoCustom/context';
import { SET_MUTED } from 'components/VideoCustom/actions';
import styles from 'components/VideoCustom/components/Mute/Mute.scss';

const Mute = ({ className }: { className?: string }) => {
  const { isTouchDevice, muted } = useStateContext();
  const { dispatch } = useDispatchContext();
  const { videoRef } = useDOMReferencesContext();
  const { label } = useTranslationsContext();
  const muteUnmuteTranslation = label?.muteUnmute ?? 'mute/unmute video';
  const [hover, setHover] = useState(false);

  const handleMuteClick = () => {
    const video = videoRef.current;
    dispatch({ type: SET_MUTED, payload: !muted });
    if (video) {
      video.muted = !muted;
    }

    // The muted attribute is set additionally, because React is not adding it to the DOM,
    // but it's needed for the unit-test
    if (video?.muted) {
      video?.setAttribute('muted', '');
    } else {
      video?.removeAttribute('muted');
    }
  };

  const toggleHover = () =>
    !isTouchDevice ? setHover((oldState) => !oldState) : null;

  const setIconData = useCallback(() => {
    if (hover && !isTouchDevice) {
      return { glyph: volumeHover, testId: 'mute.icon.hover' };
    }

    if (muted) {
      return { glyph: volumeOff, testId: 'mute.icon.off' };
    }

    return { glyph: volumeOn, testId: 'mute.icon.on' };
  }, [hover, isTouchDevice, muted]);

  return (
    <IconButton
      ariaLabel={muteUnmuteTranslation}
      className={classnames(styles.button, className)}
      data-testid={setIconData().testId}
      glyph={setIconData().glyph}
      onClick={handleMuteClick}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    />
  );
};

export default Mute;
