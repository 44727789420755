import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import createIntersectionObserver from '@xxxlgroup/hydra-utils/observers';

export const DEFAULT_OPTIONS: UseInViewportOptions = {
  once: false,
  root: null,
  rootMargin: '0px 0px 0px 0px',
  isIntersecting: (entry) => entry.isIntersecting,
  skip: false,
  threshold: 0,
};

export interface UseInViewportOptions extends IntersectionObserverInit {
  once?: boolean;
  isIntersecting?: (entry: IntersectionObserverEntry) => boolean;
  skip?: boolean;
}
const SSRUseInViewport = () => ({ setRef: noop, isInViewport: true });
const useInViewport = (options: UseInViewportOptions = DEFAULT_OPTIONS) => {
  // On SSR for now we assume that everything's always visible
  const [isInViewport, setIsInViewport] = useState(false);

  const {
    once,
    root,
    rootMargin,
    skip,
    isIntersecting = DEFAULT_OPTIONS.isIntersecting,
  } = options;

  const threshold = Array.isArray(options.threshold)
    ? JSON.stringify(options.threshold)
    : options.threshold || '0';

  const observer = useMemo(() => {
    const intersectionObserver = createIntersectionObserver(
      (entries) => {
        const intersects = entries.some((entry) => isIntersecting?.(entry));
        setIsInViewport(intersects);
        if (once && intersects) {
          intersectionObserver.disconnect();
        }
      },
      { root, rootMargin, threshold: JSON.parse(threshold.toString()) },
    );

    return intersectionObserver;
  }, [isIntersecting, once, root, rootMargin, threshold]);

  useEffect(
    () => () => {
      observer.disconnect();
    },
    [observer],
  );

  const elementRef = useRef<HTMLElement | null>();
  const setRef = useCallback(
    (element: HTMLElement | null) => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }

      if (skip) {
        return;
      }

      if (element) {
        observer.observe(element);
      }

      elementRef.current = element;
    },
    [observer, skip],
  );

  return { setRef, isInViewport };
};

const IsomorphicUseInViewport =
  CONFIG.IS_SSR || window.ssr ? SSRUseInViewport : useInViewport; // eslint-disable-line ssr-friendly/no-dom-globals-in-module-scope

export default IsomorphicUseInViewport;
