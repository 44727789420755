import {
  SET_BUFFERED,
  SET_ERROR,
  SET_CURRENT_TIME,
  SET_DURATION,
  SET_FULLSCREEN_STATUS,
  SET_FULLSCREEN_IS_ENABLED_ON_BROWSER,
  SET_IS_LOADING,
  SET_IS_TOUCH_DEVICE,
  SET_MUTED,
  SET_USER_IS_ACTIVE_ON_FULLSCREEN,
  SET_VIDEO_ENDED,
  SET_VIDEO_FIRST_PLAY_DONE,
  SET_VIDEO_PLAYING,
} from 'components/VideoCustom/actions';

export interface InitialState {
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  buffered: TimeRanges | null;
  error: boolean;
  errorCode: number | string;
  muted: boolean;
  fullScreenActive: boolean;
  fullScreenEnabled: boolean;
  fullScreenActiveUser: boolean;
  isLoading: boolean;
  isTouchDevice: boolean;
  timeCurrent: number;
  timeDuration: number;
  timeEnded: boolean;
  videoFirstPlayDone: boolean;
  videoPlaying: boolean;
}

export const initialState: InitialState = {
  error: false,
  errorCode: 0,
  buffered: null,
  muted: true,
  fullScreenActive: false,
  fullScreenActiveUser: false,
  fullScreenEnabled: false,
  isLoading: false,
  isTouchDevice: false,
  timeCurrent: 0,
  timeDuration: 0,
  timeEnded: false,
  videoPlaying: false,
  videoFirstPlayDone: false,
};

export interface Action {
  type: string;
}

export interface GeneralVideoAction extends Action {
  payload: boolean;
}

export interface VideoTimeAction extends Action {
  payload: number;
}

export interface SetBufferdAction extends Action {
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  payload: TimeRanges | null;
}

export interface SetErrorAction extends Action {
  payload: { code: number; exists: boolean };
}

function isVideoTimeAction(action: Action): action is VideoTimeAction {
  return action.type === SET_DURATION || action.type === SET_CURRENT_TIME;
}

function isSetBufferdAction(action: Action): action is SetBufferdAction {
  return action.type === SET_BUFFERED;
}

function isSetErrorAction(action: Action): action is SetErrorAction {
  return action.type === SET_ERROR;
}

function isGeneralVideoAction(action: Action): action is GeneralVideoAction {
  return [
    SET_VIDEO_PLAYING,
    SET_VIDEO_FIRST_PLAY_DONE,
    SET_VIDEO_ENDED,
    SET_USER_IS_ACTIVE_ON_FULLSCREEN,
    SET_MUTED,
    SET_IS_TOUCH_DEVICE,
    SET_IS_LOADING,
    SET_FULLSCREEN_IS_ENABLED_ON_BROWSER,
    SET_FULLSCREEN_STATUS,
    SET_ERROR,
  ].includes(action.type);
}

export type ReducerAction = Action &
  (GeneralVideoAction | VideoTimeAction | SetBufferdAction | SetErrorAction);

export function reducer(state: InitialState, action: Action) {
  if (isSetErrorAction(action)) {
    return {
      ...state,
      error: action.payload.exists,
      errorCode: action.payload.code,
    };
  }

  if (isSetBufferdAction(action)) {
    return {
      ...state,
      buffered: action.payload,
    };
  }

  if (isVideoTimeAction(action)) {
    switch (action.type) {
      case SET_CURRENT_TIME:
        return { ...state, timeCurrent: action.payload };
      case SET_DURATION:
        return { ...state, timeDuration: action.payload };
      default:
        return state;
    }
  }

  if (isGeneralVideoAction(action)) {
    switch (action.type) {
      case SET_FULLSCREEN_STATUS:
        return { ...state, fullScreenActive: action.payload };
      case SET_FULLSCREEN_IS_ENABLED_ON_BROWSER:
        return { ...state, fullScreenEnabled: action.payload };
      case SET_IS_LOADING:
        return { ...state, isLoading: action.payload };
      case SET_IS_TOUCH_DEVICE:
        return { ...state, isTouchDevice: true };
      case SET_MUTED:
        return { ...state, muted: action.payload };
      case SET_USER_IS_ACTIVE_ON_FULLSCREEN:
        return { ...state, fullScreenActiveUser: action.payload };
      case SET_VIDEO_ENDED:
        return { ...state, timeEnded: action.payload };
      case SET_VIDEO_FIRST_PLAY_DONE:
        return { ...state, videoFirstPlayDone: true };
      case SET_VIDEO_PLAYING:
        return { ...state, videoPlaying: action.payload };
      default:
        return state;
    }
  }

  return state;
}
