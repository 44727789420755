import React, { useCallback, useEffect } from 'react';
import { ReducerAction } from 'components/VideoCustom/reducer';
import {
  SET_BUFFERED,
  SET_CURRENT_TIME,
  SET_DURATION,
  SET_ERROR,
  SET_IS_LOADING,
  SET_IS_TOUCH_DEVICE,
  SET_MUTED,
  SET_VIDEO_ENDED,
} from 'components/VideoCustom/actions';
import { HTMLVideoCustomElement } from 'components/VideoCustom/context';

export function useVideoEventListeners(
  onEnded: (event: Event) => void,
  dispatch: React.Dispatch<ReducerAction>,
  playerRef: React.RefObject<HTMLDivElement>,
  videoRef: React.RefObject<HTMLVideoCustomElement>,
  onPlayProgessChanged: (percentagePlayed: number) => void,
  muted?: boolean,
) {
  useEffect(() => {
    const player = playerRef.current;
    const video = videoRef.current;
    dispatch({ type: SET_MUTED, payload: video?.muted ?? false });
    if (video?.muted) {
      video.setAttribute('muted', '');
    }

    const handleOnTouchStart = () => {
      dispatch({ type: SET_IS_TOUCH_DEVICE, payload: true });
      player?.removeEventListener('touchstart', handleOnTouchStart, false);
    };

    const updateDuration = () =>
      dispatch({ type: SET_DURATION, payload: video?.duration ?? null });

    const handleVideoEnded = (event: Event) => {
      dispatch({ type: SET_VIDEO_ENDED, payload: true });
      onEnded(event);
    };

    const handleProgress = () =>
      dispatch({ type: SET_BUFFERED, payload: video?.buffered ?? null });

    const handleError = (event: Event) => {
      const { code } = (event.target as HTMLMediaElement).error || {
        code: 1,
      };
      dispatch({
        type: SET_ERROR,
        payload: {
          exists: true,
          code,
        },
      });
    };

    const showLoading = () => dispatch({ type: SET_IS_LOADING, payload: true });

    const hideLoading = () =>
      dispatch({ type: SET_IS_LOADING, payload: false });

    if (player && video) {
      player.addEventListener('touchstart', handleOnTouchStart, false);
      video.addEventListener('loadedmetadata', updateDuration);
      video.addEventListener('waiting', showLoading);
      video.addEventListener('seeking', showLoading);
      video.addEventListener('seeked', hideLoading);
      video.addEventListener('canplay', hideLoading);
      video.addEventListener('ended', handleVideoEnded);
      video.addEventListener('error', handleError);
      video.addEventListener('progress', handleProgress);
      return () => {
        player.removeEventListener('touchstart', handleOnTouchStart, false);
        video.removeEventListener('loadedmetadata', updateDuration);
        video.removeEventListener('waiting', showLoading);
        video.removeEventListener('seeking', showLoading);
        video.removeEventListener('seeked', hideLoading);
        video.removeEventListener('canplay', hideLoading);
        video.removeEventListener('ended', handleVideoEnded);
        video.removeEventListener('error', handleError);
        video.removeEventListener('progress', handleProgress);
      };
    }
    return () => null;
  }, [dispatch, onEnded, playerRef, videoRef]);

  const updateTime = useCallback(() => {
    if (videoRef.current) {
      const { played, duration, currentTime } = videoRef.current;
      if (onPlayProgessChanged) {
        let totalPlayed = 0;
        for (let i = 0; i < played.length; i += 1) {
          totalPlayed += played.end(i) - played.start(i);
        }
        const percentagePlayed = (totalPlayed / duration) * 100;
        onPlayProgessChanged(percentagePlayed);
      }
      return dispatch({ type: SET_CURRENT_TIME, payload: currentTime });
    }

    return null;
  }, [dispatch, onPlayProgessChanged, videoRef]);

  useEffect(() => {
    const video = videoRef.current;
    video?.addEventListener('timeupdate', updateTime);
    return () => video?.removeEventListener('timeupdate', updateTime);
  }, [updateTime, videoRef]);
  useEffect(() => {
    const video = videoRef.current;
    const handleVolumeChange = () =>
      dispatch({ type: SET_MUTED, payload: video?.muted ?? false });
    video?.addEventListener('volumechange', handleVolumeChange);
    return () => video?.removeEventListener('volumechange', handleVolumeChange);
  }, [dispatch, muted, videoRef]);
}
